
.tree-input {
    position: relative;
    min-height: 1em;
}

.tree-input.open {
    padding: 0;
    background-color: transparent;
    border: none;
    margin-bottom: 32px;
}

.tree-input p {
    margin: 0;
}

/*.tree-dropdown {*/
/*    display: none;*/
/*}*/

.tree-dropdown {
    margin: 0 !important;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 8px 8px !important;
    gap: 8px;
    width: 100% !important;
    border: 1px solid var(--secondary) !important;
}

.tree-select-node {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.tree-dropdown {
    font-size: 16px;
    color: #4c4c4c;
    font-weight: 500;
}

.tree-select-node .tree-select-node {
    margin-left: 16px;
}
